import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { isEmpty, last, map } from "lodash";
import moment from "moment";

import { UsersService } from "../account-management/users/users.service";
import { Tag, Source, KeyMap } from "../../models/shared";

import { ScteService } from "./scte.service";
import { SCTELog, SCTEFilter } from "./scte";
import { LogDetailModalService } from "./log-detail-modal.service";
import { ResizeService } from "src/app/services/resize.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { DatePipe } from "@angular/common";
import { ZxTableActionsComponent } from "src/app/components/shared/zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "src/app/components/shared/zx-table-actions/zx-table-actions.table-adapter";
import { UntypedFormControl } from "@angular/forms";
import { Constants } from "src/app/constants/constants";
import { scte35ColorsService } from "../../helpers/scte-35-colors.service";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { TimestampPipe } from "src/app/pipes/timestamp.pipe";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
interface State {
    page: number;
    pageSize: number;
}

const IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY = "scte35.isScte35ColorsVisible";

@Component({
    selector: "app-scte",
    templateUrl: "./scte.component.html",
    providers: [DatePipe]
})
export class ScteComponent implements OnInit, OnDestroy {
    loading = true;
    scteLogs: SCTELog[] = [];
    isAdmin: boolean;

    scteFilter: SCTEFilter;
    showFromPicker: boolean;
    showToPicker: boolean;
    fromCounter = 0;
    toCounter = 0;
    fromDate: string;
    toDate: string;

    constants = Constants;

    selectedResourceTags: Tag[];
    selectedSources: Source[];
    msgFilter: string;
    sourceIDs: number[];

    showFilter: boolean;
    autoPageSize: boolean;
    selectedPageSize: string | number;

    tableExpanded: boolean;
    morePages = true;

    private scteLogsSubscription: Subscription;
    private isAdminSubscription: Subscription;
    private resizeSubscription: Subscription;

    private scteLogsBS$ = new BehaviorSubject<SCTELog[]>([]);

    private state: State = {
        page: 1,
        pageSize: 100
    };
    isScte35ColorsVisible = false;

    tagsControl: UntypedFormControl = new UntypedFormControl([]);
    dateFormat = "medium";

    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    tableColumnsSchema: TableSchema<KeyMap<SCTELog>>[] = [
        {
            header: this.translate.instant("SOURCE"),
            columnDef: "source_name",
            width: 140,
            visible: true,
            sticky: 1,
            style: this.getCellStyle.bind(this),
            valueToExport: row => row.source_name
        },
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date",
            width: 220,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<SCTELog>>(row => row.created_at),
            style: this.getCellStyle.bind(this),
            valueToExport: row => this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a")
        },
        {
            header: this.translate.instant("PID"),
            columnDef: "pid",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.pid.toString(),
                row => row.pid.toString(),
                () => true
            ),
            style: this.getCellStyle.bind(this),
            valueToExport: row => row.pid.toString()
        },
        {
            header: this.translate.instant("AUTO_RETURN"),
            columnDef: "auto_return",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.auto_return ? "Yes" : "No"),
                row => (row.auto_return ? "Yes" : "No"),
                () => true
            ),
            textValue: row => (row.auto_return ? "Yes" : "No"),
            valueToExport: row => (row.auto_return ? "Yes" : "No"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PRE_ROLL"),
            columnDef: "pre_roll",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.pre_roll ? String(row.pre_roll * 90) : "-"),
                row => (row.pre_roll ? String(row.pre_roll * 90) : "-"),
                () => true
            ),
            textValue: row => (row.pre_roll ? String(row.pre_roll * 90) : "-"),
            valueToExport: row => (row.pre_roll ? String(row.pre_roll * 90) : "-"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PTS"),
            columnDef: "pts_time",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => this.time_90Khz(row.pts_time),
                row => this.time_90Khz(row.pts_time),
                () => true
            ),
            textValue: row => this.time_90Khz(row.pts_time),
            valueToExport: row => this.time_90Khz(row.pts_time),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_ASCII"),
            columnDef: "segmentation_upid_ascii",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_ascii,
                row => row.segmentation_upid_ascii,
                () => true
            ),
            textValue: row => row.segmentation_upid_ascii,
            valueToExport: row => row.segmentation_upid_ascii,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_HEX"),
            columnDef: "segmentation_upid_hex",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_hex,
                row => row.segmentation_upid_hex,
                () => true
            ),
            textValue: row => row.segmentation_upid_hex,
            valueToExport: row => row.segmentation_upid_hex,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE_HEX"),
            columnDef: "seg_type_code",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                () => true
            ),
            textValue: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            valueToExport: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE"),
            columnDef: "segmentation_type",
            width: 140,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.seg_type,
                row => row.seg_type,
                () => true
            ),
            textValue: row => row.seg_type,
            valueToExport: row => row.seg_type,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.message,
                row => row.message,
                () => true
            ),
            valueToExport: row => row.message,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SCTE35"),
            columnDef: "scte35",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: row => row.scte_msg
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "info-circle",
                    translateTitle: "DETAILS",
                    onClickHandler: (row: SCTELog) => this.logDetails(row),
                    isTextVisible: false
                }
            ]),
            style: this.getCellStyle.bind(this)
        }
    ];

    constructor(
        private rs: ResizeService,
        private ss: ScteService,
        private userService: UsersService,
        private ldms: LogDetailModalService,
        private translate: TranslateService,
        private titleService: TitleService,
        private tsp: TimestampPipe,
        private datePipe: DatePipe
    ) {
        // Set Title
        this.titleService.setTitle("SCTE_35", "");
    }

    ngOnInit() {
        // Setup
        this.scteLogs = [];
        this.loading = true;
        this.msgFilter = "";

        // Subscriptions
        this.isAdminSubscription = this.userService.isAdmin.subscribe(bool => {
            this.isAdmin = bool;
        });

        // Resize
        this.resizeSubscription = this.rs.getCurrentSize.subscribe(x => {
            if (x > 3) {
                this.showFilter = false;
            }
        });

        this.scteLogsSubscription = this.ss.scteLogs.subscribe(scteLogs => {
            this.scteLogs = scteLogs;
            if (this.scteLogs) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // getLogs
        this.ss.getSCTELogs(this.prepFilter());

        const isScte35ColorsVisible = localStorage.getItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY);
        if (isScte35ColorsVisible && isScte35ColorsVisible === "true") {
            this.isScte35ColorsVisible = true;
        }
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
        this.isAdminSubscription.unsubscribe();
        this.scteLogsSubscription.unsubscribe();
        this.ss.clearSCTELogs();
    }

    get scteLogs$() {
        return this.scteLogsBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    // Prep Table Data
    private prepTableData() {
        this.scteLogs = this.scteLogs
            .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
            .reverse();
        this.scteLogsBS$.next(this.scteLogs);
    }

    // Prep Filter
    prepFilter(overrides: Partial<SCTEFilter> = {}, useOffset = false): SCTEFilter {
        this.scteFilter = Object.assign(
            {
                pageSize: this.pageSize,
                offset: useOffset
                    ? isEmpty(this.scteLogs)
                        ? undefined
                        : JSON.stringify(last(this.scteLogs).searchAfter)
                    : undefined,
                fromDate: this.fromDate ? moment(this.fromDate) : null,
                toDate: this.toDate ? moment(this.toDate) : null,
                search: this.msgFilter,
                resourceTags: map(this.selectedResourceTags, "id"),
                source_id: map(this.selectedSources, "id")
            },
            overrides
        );

        return this.scteFilter;
    }

    // Clear Filter
    clearFilter() {
        this.fromDate = null;
        this.toDate = null;
        this.msgFilter = null;
        this.selectedResourceTags = null;
        this.selectedSources = null;
    }

    // Reload Logs
    async reloadLogs() {
        this.loading = true;
        this.page = 0;
        await this.ss.refreshSCTELogs(this.prepFilter({ pageSize: 100 }));
        this.loading = false;
        this.morePages = this.checkIfMorePages();
    }

    // Reset Logs
    async resetLogs() {
        this.loading = true;
        this.clearFilter();
        this.page = 0;
        await this.ss.refreshSCTELogs(this.prepFilter({ pageSize: 100 }));
        this.loading = false;
        this.morePages = this.checkIfMorePages();
    }

    // Log Details
    async logDetails(log: SCTELog) {
        await this.ldms.logDetails(log);
    }

    // From
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    clearFromDate() {
        this.fromDate = null;
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            this.fromDate = moment(this.fromDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
        }
    }

    // To
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    clearToDate() {
        this.toDate = null;
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            this.toDate = moment(this.toDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
        }
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    async currentPageInfo(page: { pageSize: number; pageIndex: number }) {
        if (this.scteLogs.length) {
            this.pageSize = page.pageSize;
            if (this.pageSize * (page.pageIndex + 2) >= this.scteLogs.length) {
                this.loading = true;
                const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;

                await this.ss.loadMoreSCTELogs(
                    this.prepFilter(
                        {
                            pageSize
                        },
                        true
                    )
                );

                this.morePages = this.checkIfMorePages();
                this.loading = false;
            }
        }
    }

    checkIfMorePages(): boolean {
        const prevCount = this.scteLogs.length;
        const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;
        return this.scteLogs.length < prevCount + pageSize;
    }

    toggleLogsStyle() {
        this.isScte35ColorsVisible = !this.isScte35ColorsVisible;
        localStorage.setItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY, this.isScte35ColorsVisible.toString());
    }

    getCellStyle(scteLog: SCTELog) {
        return this.isScte35ColorsVisible
            ? scte35ColorsService.getStyle(scteLog.segmentation_type_id, scteLog.seg_type)
            : "";
    }

    private time_90Khz(time?: number): string {
        if (!time) return "";
        //  Work around the fact that moment.duration does not have format functionality.
        const timeMs = Math.floor((time + 45) / 90);
        return moment.utc(timeMs).format("HH:mm:ss.SSS");
    }
}
